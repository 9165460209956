import { css } from 'astroturf';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import kebabCase from 'lodash/kebabCase';
import React from 'react';

import Link from './link';

const { renderToStaticMarkup } = require('react-dom/server');

function escapeDoubleQuotes(str) {
  return str.replace(/\\([\s\S])|(")/g, '\\$1$2'); // thanks @slevithan!
}

function renderHTML(rawMDX) {
  return escapeDoubleQuotes(renderToStaticMarkup(React.createElement(MDXRenderer, null, rawMDX)));
}

const styles = css`
  .docContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: 40rem) {
      flex-direction: row;
      align-items: flex-start;
    }

    & h2 {
      font-size: 1rem;
      margin: 0;
    }
  }

  .menu {
    position: sticky;
    top: 80px;
    z-index: 9;

    & > ul {
      display: none;
      list-style: none;
    }

    & button {
      font-weight: 400;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      border-radius: 30px;
      font-size: 1rem;
      padding: 0 1rem;
      background: #107db5;
      color: #fff;
      border: 1px solid #107db5;
      transition: 0.25s ease-out all;
      margin: 0 0 0.5rem;
      &:hocus {
        background: #fff;
        color: #107db5;
      }
    }
    @media (min-width: 40rem) {
      overflow-y: visible;
      max-height: initial;
      flex: 1;
      display: block;

      & > button {
        display: none;
      }
      & > ul {
        display: block;
      }
    }
  }

  .open {
    & > ul {
      background: #fff;
      display: block;
      overflow: auto;
      max-height: calc(100vh - 118px);
    }
  }

  .content {
    flex: 2;

    & > *:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media (min-width: 40rem) {
      padding-left: 1.5rem;
    }
  }
  .glossary {
    position: relative;
    & > div {
      padding-bottom: 5rem;

      & + div {
        padding-top: 5rem;
        border-top: 1px solid #eee;
      }

      & dt {
        font-weight: bold;
        float: left;

        &::after {
          content: '—';
          margin: 0 0.5rem;
        }
      }

      & blockquote {
        padding-left: 1rem;
        border-left: 5px solid #107db5;
      }

      & dd > p + * {
        margin-top: 1.5rem;
      }
    }
  }

  .cta {
    padding-left: 2rem;
    padding-right: 2rem;
    background: rgba(16, 125, 181, 0.15);
  }
`;

class Termlisting extends React.Component {
  constructor() {
    super();

    this.state = {
      menuShown: false,
    };
  }

  showMenu = () => {
    const { menuShown } = this.state;
    this.setState({ menuShown: !menuShown });
  };

  getDocList() {
    const docList = [];
    this.props.docEdges.forEach((docEdge) => {
      docList.push({
        slug: docEdge.node.fields.slug,
        title: docEdge.node.frontmatter.title,
        body: docEdge.node.body,
      });
    });
    return docList;
  }

  render() {
    const menuClasses = [styles.menu, this.state.menuShown ? styles.open : undefined].filter((x) => x).join(' ');
    const docList = this.getDocList();

    const jsonLD = `{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [${docList
        .map(
          (post) => `{
          "@type": "Question",
          "name": "What is ${post.title}?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${renderHTML(post.body)}"
          }
        }
          `
        )
        .join(',')}]
    }`;

    return (
      <div className={styles.docContainer}>
        <div className={menuClasses}>
          <ul className="sideList">
            <li>
              <h2>Responsive design terms</h2>
            </li>
            {docList.map((post) => (
              <li>
                <a href={`#${kebabCase(post.title)}`}>{post.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <section className={styles.content}>
          <dl className={styles.glossary}>
            {docList.map((post, index) => (
              <>
                <div id={kebabCase(post.title)}>
                  <dt>
                    <Link to={post.slug}>{post.title}</Link>
                  </dt>
                  <dd>
                    <MDXRenderer>{post.body}</MDXRenderer>
                  </dd>
                </div>

                {index % 5 === 1 ? (
                  <div className={styles.cta}>
                    <dt>Sorry for interrupting...</dt>
                    <dd>
                      <p>
                        Hi, I'm Kilian. I wrote this guide, and I'm building a browser that makes responsive design easy
                        and fun.
                      </p>

                      <p>
                        Polypane shows your site in multiple viewports at the same time, and syncs all interactions
                        between them. It does a bunch more, but that's the basic idea.
                      </p>
                      <p>
                        <strong>Polypane is made for people like you</strong>.{' '}
                        <Link to="https://dashboard.polypane.app/register">Start a free trial</Link>.
                      </p>
                    </dd>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
          </dl>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLD }} />
        </section>
      </div>
    );
  }
}

export default Termlisting;
