import { graphql } from 'gatsby';
import React from 'react';
import CTA from '../components/CTA';
import ExitIntent from '../components/ExitIntent';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import TermListing from '../components/TermListing';
import Layout from '../layout';

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      menuShown: false,
    };
  }

  showMenu = () => {
    const { menuShown } = this.state;
    this.setState({ menuShown: !menuShown });
  };

  render() {
    const Terms = this.props.data.terms.edges;

    return (
      <Layout>
        <SEO
          title="Responsive design glossary"
          keywords={['']}
          description="A practical reference for anyone getting started with responsive design and development."
          pathname={this.props.location.pathname}
        />
        <PageHeader>
          <h1>Responsive design glossary</h1>
          <p>
            The responsive design glossary explains the meaning of the many terms used in responsive design and
            development. It's a practical reference for anyone getting started with responsive design and development.
          </p>
        </PageHeader>
        <div className="widePage">
          <TermListing docEdges={Terms} />
        </div>
        <CTA />
        <ExitIntent />
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TermDocIndexQuery {
    terms: allMdx(
      filter: { fields: { collection: { eq: "terms" } } }
      limit: 2000
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          body
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
